<template>
  <div
    class="folder"
    @mouseover="mouseoverItem"
    @mouseleave="mouseleaveItem"
    :style="{ zIndex: item.hover ? 10 : 'auto' }"
  >
    <div
      class="flex-center folder-img"
      @click="open"
    >
      <img
        src="../../assets/images/folder-system.png"
        alt=""
        class="img-forder"
        v-if="item.type === 'system'"
      >
      <img
        src="../../assets/images/folder-user.png"
        alt=""
        class="img-forder"
        v-else
      >
    </div>
    <!-- <div
      class="folder-type"
      v-if="item.type === 'system'"
    >{{ $t('material.commonMaterial') }}</div> -->
    <div class="folder-content">
      <div class="flex-row-center">
        <el-tooltip
          :content="$t('material.systemMaterial')"
          v-if="item.type === 'system'"
        >
          <div class="folder-system flex-center">
            <i class="iconfont icon-xitongsucai"></i>
          </div>
        </el-tooltip>
        <div class="folder-title single-line-ellipsis">{{ item.name }}</div>
      </div>
      <el-tooltip
        :content="getLabels(item.label)"
        v-if="getLabels(item.label)"
      >
        <div class="folder-label single-line-ellipsis">
          {{ getLabels(item.label) }}
        </div>
      </el-tooltip>
      <div
        v-else
        class="folder-label"
      ></div>
      <div
        class="folder-operation flex-center f-around"
        v-if="item.hover && item.type !== 'system'"
      >
        <el-tooltip
          :content="$t('edit')"
          v-if="item.type !== 'system'"
        >
          <i
            class="iconfont icon-edit color-blue mr10"
            @click="openEditDialog"
          ></i>
        </el-tooltip>
        <el-tooltip
          :content="$t('remove')"
          v-if="item.type !== 'system'"
        >
          <i
            class="iconfont icon-delete color-blue mr10"
            @click="deleteMaterialType"
          ></i>
        </el-tooltip>
      </div>
      <div
        v-if="item.hover && item.type === 'system'"
        class="folder-operation flex-center font12"
      >
        {{ $t('material.systemMaterial') }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    folder: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      item: {},
    };
  },
  watch: {
    folder: {
      handler(newVal, oldVal) {
        this.item = { ...newVal };
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    getLabels(labels) {
      return labels
        ? labels
            .split(",")
            .map((label) => `#${label}`)
            .join("    ")
        : "";
    },

    open() {
      this.$emit("open-folder", this.item);
    },
    openEditDialog() {
      this.$emit("edit-folder", this.item);
    },
    deleteMaterialType() {
      this.$emit("delete-folder", this.item);
    },
    mouseleaveItem() {
      this.$set(this.item, "hover", false);
    },
    mouseoverItem() {
      this.$set(this.item, "hover", true);
    },
  },
};
</script>
<style lang="scss" scoped>
.folder {
  width: 100%;
  // height: 228px;
  margin: 0 29px 24px 0;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  position: relative;
}
// .folder:hover {
//   box-shadow: 0 0 10px #ccc;
// }
.folder-img {
  width: 100%;
  aspect-ratio: 5 / 3;
  // height: 144px;
  background: #fbfbfb;
  border-radius: 12px;
  min-height: 100px;

  .img-forder {
    width: 66px;
    height: 54px;
  }
}
.folder-type {
  position: absolute;
  left: 0px;
  top: 0px;
  background: #0156ff;
  color: white;
  padding: 3px 6px;
  font-size: 10px;
  height: 24px;
  border-radius: 8px 4px 4px 0px;
}
.folder-content {
  height: 84px;
  padding-top: 8px;
  .folder-system {
    width: 24px;
    height: 24px;
    background: #f2f6ff;
    border-radius: 18px;
    margin-right: 8px;
    i {
      color: #0156ff;
      font-size: 12px;
    }
  }
  .folder-title {
    height: 24px;
    font-family: PingFang-SC, PingFang-SC;
    font-weight: bold;
    font-size: 14px;
    color: #000000;
    line-height: 24px;
    text-align: left;
    font-style: normal;
  }
  .folder-label {
    height: 14px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #606060;
    line-height: 14px;
    text-align: left;
    font-style: normal;
    margin-top: 4px;
  }
  .folder-operation {
    margin-top: 12px;
    color: #7f7e7f;
    i {
      font-size: 16px;
      color: #7f7e7f;
      cursor: pointer;
    }
    i:hover {
      color: #0156ff;
    }
  }
}
</style>
