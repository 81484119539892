var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "video-item",
      style: { zIndex: _vm.item.hover ? 10 : "auto" },
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.toggleSelection($event)
        },
        mouseover: _vm.mouseoverItem,
        mouseleave: _vm.mouseleaveItem,
      },
    },
    [
      _c("div", { staticClass: "left-section" }, [
        _vm.item.status == 0
          ? _c(
              "div",
              {
                staticClass: "video-cover video-bg flex-column-center f-center",
              },
              [
                _c("i", { staticClass: "iconfont icon-shipincaogao" }),
                _c("span", { staticClass: "mt8" }, [
                  _vm._v(_vm._s(_vm.$t("videoManager.statusDraft"))),
                ]),
              ]
            )
          : _vm.item.status == 1
          ? _c(
              "div",
              {
                staticClass: "video-cover video-bg flex-column-center f-center",
              },
              [
                _c("i", {
                  staticClass: "iconfont icon-shipinshengchengzhong ",
                }),
                _c("span", { staticClass: "mt8" }, [
                  _vm._v(_vm._s(_vm.$t("status.generating")) + "..."),
                ]),
              ]
            )
          : _vm.item.status == 2
          ? _c(
              "div",
              {
                staticClass: "video-cover video-bg flex-column-center f-center",
              },
              [
                _c("i", {
                  staticClass: "iconfont icon-shipinshengchengshibai ",
                }),
                _c("span", { staticClass: "mt8" }, [
                  _vm._v(_vm._s(_vm.$t("workbench.generateFail"))),
                ]),
              ]
            )
          : _c("div", { staticClass: "video-cover" }, [
              _c("video", {
                staticClass: "video-cover",
                attrs: { src: _vm.item.url, alt: "video" },
              }),
              _vm.item.videoTotalLength && _vm.item.videoTotalLength > 0
                ? _c("span", { staticClass: "video-duration" }, [
                    _vm._v(
                      _vm._s(_vm.secondsFormatTime(_vm.item.videoTotalLength))
                    ),
                  ])
                : _vm._e(),
            ]),
      ]),
      _c("div", { staticClass: "middle-section" }, [
        _c("div", { staticClass: "video-name single-line-ellipsis" }, [
          _vm._v(_vm._s(_vm.item.taskName)),
        ]),
        _c(
          "div",
          { staticClass: "flex-row-center f-between video-created" },
          [
            _c("dict-tag", {
              staticClass: "f-shrink",
              attrs: {
                options: _vm.dict.type.issue_type,
                value: _vm.item.taskType,
              },
            }),
            _c("div", { staticClass: "video-time single-line-ellipsis ml5" }, [
              _vm._v(_vm._s(_vm.item.createTime)),
            ]),
          ],
          1
        ),
        !_vm.item.hover
          ? _c(
              "div",
              {
                staticClass: "publish-status",
                class: "publish-status-" + _vm.item.publishStatus,
              },
              [
                _vm.item.publishStatus == 0
                  ? _c("div", { staticClass: "flex-row-center" }, [
                      _c("i", { staticClass: "iconfont icon-dacha3" }),
                      _vm._v(
                        " " + _vm._s(_vm.$t("issueManager.unpublished")) + " "
                      ),
                    ])
                  : _vm._e(),
                _vm.item.publishStatus == 1
                  ? _c("div", { staticClass: "flex-row-center" }, [
                      _c("i", { staticClass: "iconfont icon-dacha1" }),
                      _vm._v(
                        " " + _vm._s(_vm.$t("issueManager.publishing")) + " "
                      ),
                    ])
                  : _vm._e(),
                _vm.item.publishStatus == 3
                  ? _c("div", { staticClass: "flex-row-center" }, [
                      _c("i", { staticClass: "iconfont icon-a-dacha2" }),
                      _vm._v(" " + _vm._s(_vm.$t("issueManager.failed")) + " "),
                    ])
                  : _vm._e(),
                _vm.item.publishStatus == 2
                  ? _c("div", { staticClass: "flex-row-center" }, [
                      _c("i", { staticClass: "iconfont icon-a-dacha3" }),
                      _vm._v(
                        " " + _vm._s(_vm.$t("issueManager.published")) + " "
                      ),
                    ])
                  : _vm._e(),
              ]
            )
          : _vm._e(),
        _vm.item.hover
          ? _c(
              "div",
              { staticClass: "flex-center right-section" },
              [
                _vm.item.status == 3 && _vm.item.publishStatus == 0
                  ? _c(
                      "el-tooltip",
                      { attrs: { content: _vm.$t("videoManager.publish") } },
                      [
                        _c("i", {
                          staticClass: "iconfont icon-xingzhuangjiehe",
                          on: {
                            click: function ($event) {
                              return _vm.publish(_vm.item)
                            },
                          },
                        }),
                      ]
                    )
                  : _vm._e(),
                _vm.item.status == "0"
                  ? _c("el-tooltip", { attrs: { content: _vm.$t("edit") } }, [
                      _c("i", {
                        staticClass: "iconfont icon-edit",
                        on: {
                          click: function ($event) {
                            return _vm.$emit("edit-video", _vm.item)
                          },
                        },
                      }),
                    ])
                  : _vm._e(),
                _vm.item.status == "3"
                  ? _c(
                      "el-tooltip",
                      { attrs: { content: _vm.$t("file.filePreview") } },
                      [
                        _c("i", {
                          staticClass: "iconfont icon-chakan",
                          on: {
                            click: function ($event) {
                              _vm.dialogVisible = true
                            },
                          },
                        }),
                      ]
                    )
                  : _vm._e(),
                _vm.item.taskType == 2
                  ? _c(
                      "el-tooltip",
                      { attrs: { content: _vm.$t("videoManager.aiPage") } },
                      [
                        _c("i", {
                          staticClass: "iconfont icon-a-xialajiantoubeifen3",
                          on: {
                            click: function ($event) {
                              return _vm.aiPage(_vm.item)
                            },
                          },
                        }),
                      ]
                    )
                  : _vm._e(),
                _vm.item.status == "3"
                  ? _c(
                      "el-tooltip",
                      { attrs: { content: _vm.$t("file.download") } },
                      [
                        _c("i", {
                          staticClass: "iconfont icon-a-dagoubeifen21",
                          on: {
                            click: function ($event) {
                              return _vm.downloadFile(_vm.item.url)
                            },
                          },
                        }),
                      ]
                    )
                  : _vm._e(),
                _c("el-tooltip", { attrs: { content: _vm.$t("remove") } }, [
                  _c("i", {
                    staticClass: "iconfont icon-delete",
                    on: {
                      click: function ($event) {
                        return _vm.$emit("delete-video", _vm.item)
                      },
                    },
                  }),
                ]),
              ],
              1
            )
          : _vm._e(),
      ]),
      _vm.dialogVisible
        ? _c("VideoButtonGrid", {
            attrs: {
              dialogVisible: _vm.dialogVisible,
              url: _vm.item.url,
              type: 0,
              tip: _vm.$t("file.filePreview"),
            },
            on: {
              closeMedia: function ($event) {
                _vm.dialogVisible = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }