<!-- 仅AI换脸使用 -->
<template>
  <div
    class="uploadboxPic"
    v-loading="loading"
  >
    <div class="material flex-center">
      <el-dropdown trigger="hover">
        <div class="flex-column-center ">
          <i class="iconfont icon-tianjia"></i>
          <el-button
            class="uploadBtn"
            type="primary"
            round
          >{{ $t('file.selectMaterial') }}</el-button>
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item>
            <div @click="picSelectMaterial">{{ $t('videoTranslationPage.selectFromLibrary') }}</div>
          </el-dropdown-item>
          <el-dropdown-item>
            <div @click="iconDrawer = true">{{ $t('videoTranslationPage.selectFromSystem') }}</div>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div class="line"></div>
    <div class="local flex-column-center f-center">
      <i class="iconfont icon-shangchuan"></i>
      <el-upload
        action=""
        accept="image/*"
        :before-upload="handleBeforeUpload"
        :limit="1"
      >
        <el-button
          class="uploadBtn"
          type="primary"
          round
        >{{ $t('file.localUpload') }}</el-button>
      </el-upload>
      <!-- <div class="material" @click="picSelectMaterial">{{ $t('file.selectMaterial') }}</div> -->
    </div>
    <system-icon-drawer
      :drawer="iconDrawer"
      @selectPic="handleSelectPic"
      @close="iconDrawer = false"
    />
  </div>
</template>
<script>
import { localTuClipAdd } from "../../api/ai/material";
import { getOssToken } from "../../api/ai/ali";
import OSS from "ali-oss";
import systemIconDrawer from "./systemIconDrawer.vue";
export default {
  components: { systemIconDrawer },
  props: {},
  watch: {},
  data() {
    return {
      credentials: null,
      loading: false,
      iconDrawer: false,
    };
  },
  computed: {},
  created() {},
  methods: {
    handleSelectPic(url) {
      this.$emit("picSelectSystem", url);
    },
    picSelectMaterial() {
      this.$emit("picSelectMaterial");
    },
    async uploadFile(file) {
      console.log(file);
      try {
        this.loading = true;
        const client = await this.getOssClient();
        const result = await client.put(
          `file/${this.getCurrentDate()}/${this.generateUniqueCode(
            30
          )}${file.name.replace(/^[^.]+/, "")}`,
          file
        );
        console.log(result, "上传至oss的文件");
        localTuClipAdd({
          ...file,
          fileType: "image",
          url: result.url,
          fileSize: file.size,
          name: file.name.replace(/\.[^/.]+$/, ""),
        })
          .then((response) => {
            console.log(response);
            this.$emit("showLocaPic", [{ url: response.data.url }]);
            // this.$emit('showLocaPic', [{url: result.url}])
            this.loading = false;
          })
          .catch((error) => {
            console.log(error);
            this.loading = false;
          });
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    async getOssClient() {
      // 临时凭证过期时重新获取，减少对STS服务的调用。
      if (this.isCredentialsExpired(this.credentials)) {
        this.credentials = await getOssToken();
      }
      return new OSS({
        bucket: process.env.VUE_APP_OSS_BUCKET, // OSS Bucket名称
        region: "oss-cn-beijing", // OSS所在地域
        accessKeyId: this.credentials.accessKeyId,
        accessKeySecret: this.credentials.accessKeySecret,
        stsToken: this.credentials.securityToken,
        timeout: 500000,
        secure: true,
      });
    },
    isCredentialsExpired(credentials) {
      if (!credentials) {
        return true;
      }
      const expireDate = new Date(credentials.Expiration);
      const now = new Date();
      return expireDate.getTime() - now.getTime() <= 60000; // 有效期不足一分钟，视为过期
    },
    handleBeforeUpload(file) {
      // 校验文件类型
      if (this.fileType) {
        const fileName = file.name.split(".");
        const fileExt = fileName[fileName.length - 1].toLowerCase();
        const isTypeOk = ["jpeg", "jpg", "png"].includes(fileExt);
        if (!isTypeOk) {
          this.$modal.msgError(
            `${this.$t("file.invalidFormat1")} JPEG/JPG/PNG ${this.$t(
              "file.invalidFormat2"
            )}`
          );
          return false;
        }
      }

      // 校验文件大小（20MB）
      const maxFileSize = 20;
      const isLt = file.size / 1024 / 1024 <= maxFileSize;
      if (!isLt) {
        this.$modal.msgError(
          `${this.$t("file.sizeExceed")} ${maxFileSize} MB!`
        );
        return false;
      }

      // 校验分辨率
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = URL.createObjectURL(file);
        img.onload = () => {
          const { width, height } = img;
          URL.revokeObjectURL(img.src);
          if (width < 128 || height < 128 || width > 4000 || height > 4000) {
            this.$modal.msgError(`${this.$t("file.invalidResolution")}`);
            reject(false);
          } else {
            this.uploadFile(file);
            // this.number++;
            resolve(true);
          }
        };
        img.onerror = () => {
          reject(false);
        };
      });
    },
    generateRandomCode(length = 30) {
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let result = "";
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters[randomIndex];
      }
      return result;
    },
    generateUniqueCode(length) {
      const generatedCodes = new Set();
      let code;
      do {
        code = this.generateRandomCode(length);
      } while (generatedCodes.has(code));
      generatedCodes.add(code);
      return code;
    },
    getCurrentDate() {
      const date = new Date();
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // 月份从0开始，所以加1
      const day = String(date.getDate()).padStart(2, "0");

      return `${year}/${month}/${day}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.uploadboxPic {
  // width: 180px;
  // height: 180px;
  width: 180px;
  height: 293px;
  background: #ffffff;
  border-radius: 12px;
  border: 1px solid #e5e7eb;

  .line {
    width: 100%;
    height: 1px;
    border: 1px dashed #e5e7eb;
  }

  .material {
    width: 180px;
    height: 145px;
  }

  .local {
    width: 180px;
    height: 145px;
    .material {
      cursor: pointer;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #0156ff;
      line-height: 22px;
      text-align: center;
      font-style: normal;
      text-decoration-line: underline;
      margin-top: 10px;
    }
  }
  .uploadBtn {
    min-width: 80px;
    height: 32px;
    background: #0156ff;
    border-radius: 18px;
    display: flex;
    justify-content: center;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #ffffff;
    line-height: 22px;
    text-align: center;
    font-style: normal;
    align-items: center;
    margin-top: 10px;
  }
  .iconfont {
    font-size: 24px;
  }
  .el-upload-list {
    margin: 0;
    padding: 0;
    display: none;
    list-style: none;
  }
}
</style>
