<template>
  <el-drawer
    class="publicDrawer"
    :size="580"
    :visible="drawer"
    :modal="true"
    :before-close="handleClose"
    :with-header="true"
    :show-close="false"
  >
    <div slot="title">
      <div class="headerFlex">
        <div class="headerTitle">{{ $t('systemAvatar.avatar') }}</div>
        <i
          class="el-icon-close"
          @click="handleClose"
        ></i>
      </div>
    </div>
    <div class="picConetnt flex-row f-wrap">
      <div
        class="itemTextBox"
        v-for="item in systemPicList"
        :key="item.name"
        @click="clickStstemPic(item)"
      >
        <div :class="['picItem', item.isSelect && 'selectPicStyle']">
          <img
            :src="item.url"
            mode=""
          >
          <div
            v-if="item.isSelect"
            class="selectIcon"
          >
            <i class="iconfont iconzhengque"></i>
          </div>
        </div>
        <div class="itemText">
          {{ item.name }}
        </div>
      </div>
    </div>
  </el-drawer>
</template>

<script>
export default {
  props: {
    drawer: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      systemPicList: [
        {
          name: this.$t("systemAvatar.zhengYi"),
          url: "https://yfwx-cg-publishing.oss-cn-beijing.aliyuncs.com/file/2024/11/14/DRvvAhHjE2ugbsVgmfMTFgd66ZCLsM.png",
          isSelect: false,
        },
        {
          name: this.$t("systemAvatar.weiLun"),
          url: "https://yfwx-cg-publishing.oss-cn-beijing.aliyuncs.com/file/2024/11/14/oupTVOjy4GHoLSsZEMufCrA8H0HjTr.png",
          isSelect: false,
        },
        {
          name: this.$t("systemAvatar.zhuoYang"),
          url: "https://yfwx-cg-publishing.oss-cn-beijing.aliyuncs.com/file/2024/11/14/cP74BmqZlaSf5QBQndZldpmdXpwrOO.png",
          isSelect: false,
        },
        {
          name: this.$t("systemAvatar.nuoYa"),
          url: "https://yfwx-cg-publishing.oss-cn-beijing.aliyuncs.com/file/2024/11/14/p6I1OYEyCG2LvaBDtXRLIFppSNnVHJ.png",
          isSelect: false,
        },
        {
          name: this.$t("systemAvatar.cuiBin"),
          url: "https://yfwx-cg-publishing.oss-cn-beijing.aliyuncs.com/file/2024/11/14/vW0kJPToMXIbq9ujKymPgcejr7XbrT.png",
          isSelect: false,
        },
        {
          name: this.$t("systemAvatar.xiYan"),
          url: "https://yfwx-cg-publishing.oss-cn-beijing.aliyuncs.com/file/2024/11/14/QzkfWifsIPK5jl67PWklOy3J27DcS7.png",
          isSelect: false,
        },
        {
          name: this.$t("systemAvatar.zhiQing"),
          url: "https://yfwx-cg-publishing.oss-cn-beijing.aliyuncs.com/file/2024/11/14/lgks9XdbEhQyXR1cVliL5yqnEACyTS.png",
          isSelect: false,
        },
        {
          name: this.$t("systemAvatar.haiLi"),
          url: "https://yfwx-cg-publishing.oss-cn-beijing.aliyuncs.com/file/2024/11/14/lw7OAC6dRyyw8LUvuK3FrI7Q2aSqrd.png",
          isSelect: false,
        },
        {
          name: this.$t("systemAvatar.shiWan"),
          url: "https://yfwx-cg-publishing.oss-cn-beijing.aliyuncs.com/file/2024/11/14/feldjvbh5F6G99eOTOw9S6o3oWo1vT.png",
          isSelect: false,
        },
        {
          name: this.$t("systemAvatar.aiMa"),
          url: "https://yfwx-cg-publishing.oss-cn-beijing.aliyuncs.com/file/2024/11/14/Ewbw83ISHnw4ufw8UGqGTsqXR8rAQy.png",
          isSelect: false,
        },
        {
          name: this.$t("systemAvatar.biLi"),
          url: "https://yfwx-cg-publishing.oss-cn-beijing.aliyuncs.com/file/2024/11/14/6irm4Xq7CSvakhRK3mcxnaHrYoWKdb.png",
          isSelect: false,
        },
      ],
    };
  },
  created() {},
  methods: {
    handleClose() {
      this.$emit("close");
    },
    clickStstemPic(item) {
      this.systemPicList = this.systemPicList.map((picItem) => {
        if (item.name === picItem.name) {
          return { ...picItem, isSelect: true };
        } else {
          return { ...picItem, isSelect: false };
        }
      });
      this.$emit("selectPic", item.url);
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.picConetnt {
  overflow: auto;
  padding: 30px;
}
.itemTextBox {
  width: 164px;
  text-align: center;
  margin-right: 11px;
  margin-bottom: 24px;
  .picItem {
    width: 164px;
    height: 164px;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    overflow: hidden;
    image {
      width: 164px;
      height: 164px;
    }
  }
  .selectPicStyle {
    width: 164px;
    height: 164px;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    border: 4px solid #6564fc;
    box-sizing: border-box;
    position: relative;
    .selectIcon {
      position: absolute;
      right: -8px;
      top: -8px;
      width: 34px;
      height: 35px;
      background: #6564fc;
      border-radius: 18px;
      display: flex;
      align-items: center;
      .iconzhengque {
        color: #fff;
        font-size: 14px;
        margin-top: 10px;
        margin-left: 6px;
      }
    }
  }
  .itemText {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 20px;
    color: #3b3b3b;
    line-height: 28px;
    text-align: center;
    font-style: normal;
    margin-top: 14px;
  }
}
.itemTextBox:nth-child(3n) {
  margin-right: 0;
}
</style>
