<template>
  <div
    class="video-item"
    @click.stop="toggleSelection"
    @mouseover="mouseoverItem"
    @mouseleave="mouseleaveItem"
    :style="{ zIndex: item.hover ? 10 : 'auto' }"
  >
    <!-- 视频封面 -->
    <div class="left-section">
      <div
        v-if="item.status == 0"
        class="video-cover video-bg flex-column-center f-center"
      >
        <i class="iconfont icon-shipincaogao"></i>
        <span class="mt8">{{ $t('videoManager.statusDraft') }}</span>
      </div>
      <div
        v-else-if="item.status == 1"
        class="video-cover video-bg flex-column-center f-center"
      >
        <i class="iconfont icon-shipinshengchengzhong "></i>
        <span class="mt8">{{ $t('status.generating') }}...</span>
      </div>
      <div
        v-else-if="item.status == 2"
        class="video-cover video-bg flex-column-center f-center"
      >
        <i class="iconfont icon-shipinshengchengshibai "></i>
        <span class="mt8">{{ $t('workbench.generateFail') }}</span>
      </div>
      <div
        v-else
        class="video-cover"
      >
        <video
          :src="item.url"
          alt="video"
          class="video-cover"
        />
        <span
          class="video-duration"
          v-if="item.videoTotalLength && item.videoTotalLength > 0"
        >{{ secondsFormatTime(item.videoTotalLength) }}</span>
      </div>
    </div>
    <!-- 视频信息 -->
    <div class="middle-section">
      <div class="video-name single-line-ellipsis">{{ item.taskName }}</div>
      <div class="flex-row-center f-between video-created">
        <dict-tag
          class="f-shrink"
          :options="dict.type.issue_type"
          :value="item.taskType"
        />
        <div class="video-time single-line-ellipsis ml5">{{ item.createTime }}</div>
      </div>
      <div
        :class="'publish-status-'+item.publishStatus"
        class="publish-status"
        v-if="!item.hover"
      >
        <div
          v-if="item.publishStatus == 0"
          class="flex-row-center"
        > <i class="iconfont icon-dacha3"></i>
          {{ $t('issueManager.unpublished') }}
        </div>
        <div
          v-if="item.publishStatus == 1"
          class="flex-row-center"
        >
          <i class="iconfont icon-dacha1"></i>
          {{ $t('issueManager.publishing') }}
        </div>
        <div
          v-if="item.publishStatus == 3"
          class="flex-row-center"
        >
          <i class="iconfont icon-a-dacha2"></i>
          {{ $t('issueManager.failed') }}
        </div>
        <div
          v-if="item.publishStatus == 2"
          class="flex-row-center"
        >
          <i class="iconfont icon-a-dacha3"></i>
          {{ $t('issueManager.published') }}
        </div>
      </div>
      <!-- 操作按钮 -->
      <div
        class="flex-center right-section"
        v-if="item.hover"
      >
        <el-tooltip
          :content="$t('videoManager.publish')"
          v-if="item.status == 3 &&item.publishStatus == 0"
        >
          <i
            class="iconfont icon-xingzhuangjiehe"
            @click="publish(item)"
          ></i>
        </el-tooltip>
        <el-tooltip
          :content="$t('edit')"
          v-if="item.status == '0'"
        >
          <i
            class="iconfont icon-edit"
            @click="$emit('edit-video',item)"
          ></i>
        </el-tooltip>
        <el-tooltip
          :content="$t('file.filePreview')"
          v-if="item.status == '3'"
        >
          <i
            class="iconfont icon-chakan"
            @click="dialogVisible=true"
          ></i>
        </el-tooltip>
        <el-tooltip
          :content="$t('videoManager.aiPage')"
          v-if="item.taskType==2"
        >
          <i
            class="iconfont icon-a-xialajiantoubeifen3"
            @click="aiPage(item)"
          ></i>
        </el-tooltip>
        <el-tooltip
          :content="$t('file.download')"
          v-if="item.status == '3'"
        >
          <i
            class="iconfont icon-a-dagoubeifen21"
            @click="downloadFile(item.url)"
          ></i>
        </el-tooltip>
        <el-tooltip :content="$t('remove')">
          <i
            class="iconfont icon-delete"
            @click="$emit('delete-video',item)"
          ></i>
        </el-tooltip>
      </div>
    </div>
    <VideoButtonGrid
      v-if="dialogVisible"
      :dialogVisible="dialogVisible"
      :url="item.url"
      :type="0"
      :tip="$t('file.filePreview')"
      @closeMedia="dialogVisible = false"
    />
  </div>
</template>

<script>
import VideoButtonGrid from "../VideoButtonGrid/VideoButton.vue";
export default {
  name: "VideoItem",
  dicts: ["issue_type"],
  components: {
    VideoButtonGrid,
  },
  props: {
    video: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      item: {},
      dialogVisible: false,
    };
  },
  watch: {
    video: {
      handler(newVal, oldVal) {
        this.item = { ...newVal };
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    //去发布
    publish(video) {
      this.$router.push({
        path: `/issueVideo/${video.productId}`,
        query: { isProductId: true, url: video.url },
      });
    },
    //AI详情
    aiPage(row) {
      this.$router.push({
        path: `/videoManager/make_video/${row.aiVideoId}`,
      });
    },
    toggleSelection() {
      // this.item.hover = !this.item.hover;
      console.log(this.item);
    },
    mouseleaveItem() {
      this.$set(this.item, "hover", false);
    },
    mouseoverItem() {
      this.$set(this.item, "hover", true);
    },
  },
};
</script>

<style lang="scss" scoped>
.video-item {
  width: 100%;
  margin: 0 29px 24px 0;
  /* border: 1px solid #dfdfdf;
  border-radius: 8px; */
  overflow: hidden;
  cursor: pointer;
  z-index: auto;
}
/* .video-item:hover {
  box-shadow: 0 0 10px #ccc;
} */
.left-section {
  position: relative;
  width: 100%;
  aspect-ratio: 5 / 3;
  border-radius: 12px;
}
.video-cover {
  width: 100%;
  aspect-ratio: 5 / 3; /* 设置宽高比为 5:3 */
  cursor: pointer;
  border-radius: 12px;
  object-fit: cover; /* 确保视频不会拉伸 */
}
.video-bg {
  background: #f3f4f6;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.4);
  line-height: 20px;
  text-align: right;
  font-style: normal;
  i {
    font-size: 30px;
  }
}
.video-duration {
  position: absolute;
  right: 8px;
  bottom: 8px;
  height: 20px;
  background: rgba(0, 0, 0, 0.9);
  border-radius: 4px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
  line-height: 20px;
  text-align: center;
  font-style: normal;
  padding: 0 6px;
}
.middle-section {
  height: 84px;
  padding-top: 8px;
  .video-name {
    height: 24px;
    font-family: PingFang-SC, PingFang-SC;
    font-weight: bold;
    font-size: 14px;
    color: #000000;
    line-height: 24px;
    text-align: left;
    font-style: normal;
  }
}

.video-description {
  font-size: 14px;
  margin-bottom: 5px;
  color: #666;
}

.video-tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 5px;
}

.tag-input {
  width: auto;
  margin-right: 5px;
}

.video-created {
  height: 22px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.9);
  line-height: 22px;
  text-align: left;
  font-style: normal;
  margin-top: 4px;
  .video-time {
    color: rgba(0, 0, 0, 0.6);
  }
}
.publish-status {
  height: 24px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  text-align: left;
  font-style: normal;
  margin-top: 2px;
  i {
    margin-right: 4px;
  }
}
.publish-status-0 {
  color: rgba(0, 0, 0, 0.4);
}
.publish-status-1 {
  color: #ed7b2f;
}
.publish-status-2 {
  color: #00a870;
}
.publish-status-3 {
  color: #e34d59;
}
.right-section {
  height: 24px;
  margin-top: 2px;
  display: flex;
  justify-content: space-around;
  i {
    color: #7f7e7f;
    font-size: 14px;
    cursor: pointer;
  }
  i:hover {
    color: #0156ff;
  }
}

/* Hover样式 */
.el-button .el-tooltip__popper.is-dark {
  background-color: rgba(0, 0, 0, 0.8) !important;
}
</style>
