var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "uploadboxPic",
    },
    [
      _c(
        "div",
        { staticClass: "material flex-center" },
        [
          _c(
            "el-dropdown",
            { attrs: { trigger: "hover" } },
            [
              _c(
                "div",
                { staticClass: "flex-column-center " },
                [
                  _c("i", { staticClass: "iconfont icon-tianjia" }),
                  _c(
                    "el-button",
                    {
                      staticClass: "uploadBtn",
                      attrs: { type: "primary", round: "" },
                    },
                    [_vm._v(_vm._s(_vm.$t("file.selectMaterial")))]
                  ),
                ],
                1
              ),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _c("el-dropdown-item", [
                    _c("div", { on: { click: _vm.picSelectMaterial } }, [
                      _vm._v(
                        _vm._s(_vm.$t("videoTranslationPage.selectFromLibrary"))
                      ),
                    ]),
                  ]),
                  _c("el-dropdown-item", [
                    _c(
                      "div",
                      {
                        on: {
                          click: function ($event) {
                            _vm.iconDrawer = true
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t("videoTranslationPage.selectFromSystem")
                          )
                        ),
                      ]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "line" }),
      _c(
        "div",
        { staticClass: "local flex-column-center f-center" },
        [
          _c("i", { staticClass: "iconfont icon-shangchuan" }),
          _c(
            "el-upload",
            {
              attrs: {
                action: "",
                accept: "image/*",
                "before-upload": _vm.handleBeforeUpload,
                limit: 1,
              },
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "uploadBtn",
                  attrs: { type: "primary", round: "" },
                },
                [_vm._v(_vm._s(_vm.$t("file.localUpload")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("system-icon-drawer", {
        attrs: { drawer: _vm.iconDrawer },
        on: {
          selectPic: _vm.handleSelectPic,
          close: function ($event) {
            _vm.iconDrawer = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }