var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-tooltip", { attrs: { content: _vm.tip } }, [
        _c(
          "div",
          { on: { click: _vm.playVideo } },
          [
            _vm._t("customButton", [
              _c("el-button", {
                attrs: { icon: "iconfont icon-bofang", circle: "" },
              }),
            ]),
          ],
          2
        ),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            width: "50%",
            "before-close": _vm.handleClose,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _vm.type === 0
            ? _c("video", {
                ref: "videoPlayer",
                staticClass: "video-player",
                attrs: { controls: "", src: _vm.url },
              })
            : _c("div", [
                _vm.video.fileType == "image"
                  ? _c("img", {
                      staticClass: "image-viewer",
                      attrs: { src: _vm.video.url, alt: "image" },
                    })
                  : _vm.video.fileType == "audio"
                  ? _c("audio", {
                      ref: "audioPlayer",
                      staticClass: "audio-player",
                      attrs: { controls: "", src: _vm.video.url },
                    })
                  : _c("video", {
                      ref: "videoPlayer",
                      staticClass: "video-player",
                      attrs: { controls: "", src: _vm.video.url },
                    }),
              ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }