var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { width: "100%" } },
    [
      _c("div", { staticClass: "flex-row-center f-between" }, [
        _c(
          "div",
          { staticClass: "videoTitle basic-info-title flex-row-center" },
          [
            _c("svg-icon", {
              staticClass: "title-bg-svg",
              attrs: { "icon-class": "text_bg" },
            }),
            _c("span", { staticClass: "ml16" }, [
              _vm._v(" " + _vm._s(_vm.$t("basicInfo.scene")) + " "),
            ]),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "set-des", staticStyle: { margin: "8px 16px" } },
        [_vm._v(_vm._s(_vm.$t("videoSetting.segmentAllocation")))]
      ),
      _c(
        "el-form",
        {
          ref: "basicInfoForm",
          attrs: {
            model: _vm.basicInfo,
            rules: _vm.rules,
            "label-width": "120px",
            "label-position": "top",
          },
        },
        [
          _c("el-form-item", { attrs: { prop: "sceneName" } }, [
            _c("div", { staticClass: "scene-video-header" }, [
              _c(
                "div",
                { staticClass: "flex-row-center" },
                [
                  _c("i", {
                    staticClass: "iconfont icon-edit pointer f-shrink",
                    on: { click: _vm.focusInput },
                  }),
                  _c("el-input", {
                    ref: "sceneNameInput",
                    staticClass: "f-grow",
                    attrs: {
                      maxlength: "50",
                      placeholder: _vm.$t("tip.input"),
                    },
                    on: { input: _vm.handleItemUpdated },
                    model: {
                      value: _vm.basicInfo.sceneName,
                      callback: function ($$v) {
                        _vm.$set(_vm.basicInfo, "sceneName", $$v)
                      },
                      expression: "basicInfo.sceneName",
                    },
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "scene-isMute flex-row-center f-shrink",
                      class: { activated: !_vm.basicInfo.isMute },
                      on: { click: _vm.changeMute },
                    },
                    [
                      _c("div", { staticClass: "cricle-white flex-center" }, [
                        _c("i", {
                          class: [
                            "iconfont icon-jingyin",
                            {
                              "activated icon-shengyin": !_vm.basicInfo.isMute,
                            },
                          ],
                        }),
                      ]),
                      _vm._v(" " + _vm._s(_vm.$t("basicInfo.isMute")) + " "),
                    ]
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "flex-row-center scene-video-num" }, [
                _vm._v(" " + _vm._s(_vm.$t("basicInfo.materialNum")) + "： "),
                _c("span", { staticStyle: { color: "rgba(0,0,0,0.9)" } }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.basicInfo.videoTrackClipsBos.length || 0) +
                      " "
                  ),
                ]),
              ]),
            ]),
          ]),
          _c("el-form-item", { attrs: { prop: "videoTrackClipsBos" } }, [
            _c(
              "div",
              { staticClass: "aspect-ratio-box-container" },
              [
                !_vm.basicInfo.videoTrackClipsBos ||
                !_vm.basicInfo.videoTrackClipsBos.length
                  ? _c("uploadFile", {
                      staticClass: "upload-size",
                      attrs: { type: "materialType" },
                      on: {
                        selectMaterial: _vm.chooseMaterial,
                        showLocaMedia: _vm.showLocaMedia,
                      },
                    })
                  : _vm._e(),
                _vm._l(
                  _vm.basicInfo.videoTrackClipsBos,
                  function (material, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "aspect-ratio-box flex-column",
                        on: {
                          mouseover: function ($event) {
                            material.hover = true
                          },
                          mouseleave: function ($event) {
                            material.hover = false
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "material-container" }, [
                          material.type === "image" ||
                          material.fileType === "image" ||
                          material.tracksType === "image"
                            ? _c("img", {
                                staticClass: "material-thumbnail",
                                attrs: {
                                  src: material.mediaUrl,
                                  alt: "material",
                                },
                              })
                            : _vm._e(),
                          material.type === "video" ||
                          material.fileType === "video" ||
                          material.tracksType === "video"
                            ? _c("video", {
                                staticClass: "material-thumbnail",
                                attrs: { src: material.mediaUrl, controls: "" },
                              })
                            : _vm._e(),
                          material.hover
                            ? _c("div", { staticClass: "overlay" }, [
                                _c(
                                  "div",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.removeMaterial(index)
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("basicInfo.remove")))]
                                ),
                                material.fileType === "video" ||
                                material.type === "video"
                                  ? _c(
                                      "div",
                                      { staticStyle: { margin: "5px" } },
                                      [_vm._v("|")]
                                    )
                                  : _vm._e(),
                                material.fileType === "video" ||
                                material.type === "video"
                                  ? _c(
                                      "div",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.pruning(material, index)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("basicInfo.pruning"))
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                        ]),
                        (material.type === "video" ||
                          material.fileType === "video" ||
                          material.tracksType === "video") &&
                        material.out
                          ? _c("div", { staticClass: "video-duration" }, [
                              _vm._v(
                                _vm._s(_vm.secondsFormatTime(material.out))
                              ),
                            ])
                          : _vm._e(),
                      ]
                    )
                  }
                ),
              ],
              2
            ),
          ]),
          _c(
            "el-form-item",
            {
              staticClass: "mt16",
              attrs: {
                label: _vm.$t("basicInfo.timeType"),
                prop: "durationType",
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "340px" },
                          on: { change: _vm.timeTypeChange },
                          model: {
                            value: _vm.basicInfo.durationType,
                            callback: function ($$v) {
                              _vm.$set(_vm.basicInfo, "durationType", $$v)
                            },
                            expression: "basicInfo.durationType",
                          },
                        },
                        _vm._l(_vm.computedTimeTypes, function (item) {
                          return _c(
                            "el-option",
                            {
                              key: item.value,
                              attrs: {
                                label: item.label,
                                value: item.value,
                                disabled: item.disabled,
                              },
                            },
                            [
                              _c("span", { staticStyle: { float: "left" } }, [
                                _vm._v(_vm._s(item.label)),
                              ]),
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    float: "right",
                                    color: "#8492a6",
                                    "font-size": "13px",
                                    "margin-left": "10px",
                                  },
                                },
                                [_vm._v(_vm._s(item.des))]
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.basicInfo.durationType === "regular"
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { "margin-left": "10px" },
                              attrs: { prop: "regularDuration" },
                            },
                            [
                              _c(
                                "el-input",
                                {
                                  staticStyle: { width: "150px" },
                                  attrs: { type: "number", min: 0, max: 9999 },
                                  on: { input: _vm.handleItemUpdated },
                                  model: {
                                    value: _vm.basicInfo.regularDuration,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.basicInfo,
                                        "regularDuration",
                                        _vm._n($$v)
                                      )
                                    },
                                    expression: "basicInfo.regularDuration",
                                  },
                                },
                                [
                                  _c("template", { slot: "append" }, [
                                    _vm._v("s"),
                                  ]),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("material-drawer", {
        ref: "materialDrawer",
        attrs: { visible: _vm.drawer },
        on: {
          "update:visible": function ($event) {
            _vm.drawer = $event
          },
          selectionConfirmed: _vm.selectionConfirmed,
        },
      }),
      _c(
        "el-drawer",
        {
          staticClass: "publicDrawer",
          attrs: {
            size: "50%",
            title: _vm.$t("basicInfo.pruningTitle"),
            visible: _vm.drawerPruning,
            "with-header": true,
            wrapperClosable: false,
            "show-close": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.drawerPruning = $event
            },
          },
        },
        [
          _c("div", { attrs: { slot: "title" }, slot: "title" }, [
            _c("div", { staticClass: "headerFlex" }, [
              _c("div", { staticClass: "headerTitle" }, [
                _vm._v(_vm._s(_vm.$t("basicInfo.pruningTitle"))),
              ]),
            ]),
          ]),
          _c("pruning", {
            ref: "pruning",
            on: { confirm: _vm.confirmPruning, cancel: _vm.cancelPruning },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }