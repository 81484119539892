<template>
  <div style="width: 100%;">
    <div class="flex-row-center f-between">
      <div class="videoTitle basic-info-title flex-row-center">
        <svg-icon
          icon-class="text_bg"
          class="title-bg-svg"
        />
        <span class="ml16">
          {{ $t('basicInfo.scene') }}
        </span>
      </div>
    </div>
    <div
      class="set-des"
      style="margin: 8px 16px;"
    >{{$t('videoSetting.segmentAllocation')}}</div>

    <el-form
      :model="basicInfo"
      :rules="rules"
      ref="basicInfoForm"
      label-width="120px"
      label-position="top"
    >
      <el-form-item prop="sceneName">
        <div class="scene-video-header">
          <div class="flex-row-center">
            <i
              class="iconfont icon-edit pointer f-shrink"
              @click="focusInput"
            ></i>
            <el-input
              ref="sceneNameInput"
              v-model="basicInfo.sceneName"
              maxlength="50"
              :placeholder="$t('tip.input')"
              class="f-grow"
              @input="handleItemUpdated"
            ></el-input>
            <div
              class="scene-isMute flex-row-center f-shrink"
              :class="{'activated': !basicInfo.isMute}"
              @click="changeMute"
            >
              <div class="cricle-white flex-center">
                <i :class="['iconfont icon-jingyin',{'activated icon-shengyin': !basicInfo.isMute}]"></i>
              </div>
              {{ $t('basicInfo.isMute') }}
            </div>
          </div>
          <div class="flex-row-center scene-video-num">
            {{ $t('basicInfo.materialNum') }}：
            <span style="color: rgba(0,0,0,0.9);">
              {{ basicInfo.videoTrackClipsBos.length || 0 }}
            </span>
          </div>
        </div>
      </el-form-item>
      <el-form-item prop="videoTrackClipsBos">
        <div class="aspect-ratio-box-container">
          <uploadFile
            v-if="!basicInfo.videoTrackClipsBos||!basicInfo.videoTrackClipsBos.length"
            class="upload-size"
            :type="'materialType'"
            @selectMaterial="chooseMaterial"
            @showLocaMedia="showLocaMedia"
          />
          <div
            v-for="(material, index) in basicInfo.videoTrackClipsBos"
            :key="index"
            class="aspect-ratio-box flex-column"
            @mouseover="material.hover = true"
            @mouseleave="material.hover = false"
          >
            <div class="material-container">
              <img
                v-if="material.type === 'image'||material.fileType === 'image'||material.tracksType=== 'image'"
                :src="material.mediaUrl"
                alt="material"
                class="material-thumbnail"
              />
              <video
                v-if="material.type === 'video'||material.fileType === 'video'||material.tracksType=== 'video'"
                :src="material.mediaUrl"
                controls
                class="material-thumbnail"
              ></video>
              <div
                v-if="material.hover"
                class="overlay"
              >
                <div @click="removeMaterial(index)">{{ $t('basicInfo.remove') }}</div>
                <div
                  style="margin: 5px;"
                  v-if="material.fileType === 'video'|| material.type === 'video'"
                >|</div>
                <div
                  @click="pruning(material,index)"
                  v-if="material.fileType === 'video'|| material.type === 'video'"
                >{{ $t('basicInfo.pruning') }}</div>
              </div>
            </div>
            <div
              class="video-duration"
              v-if="(material.type === 'video'|| material.fileType === 'video'||material.tracksType=== 'video') && material.out"
            >{{ secondsFormatTime(material.out) }}</div>
          </div>
        </div>
      </el-form-item>
      <el-form-item
        :label="$t('basicInfo.timeType')"
        prop="durationType"
        class="mt16"
      >
        <el-row>
          <el-col :span="12">
            <el-select
              v-model="basicInfo.durationType"
              @change="timeTypeChange"
              style="width:340px"
            >
              <el-option
                v-for="item in computedTimeTypes"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                :disabled="item.disabled"
              >
                <span style="float: left">{{ item.label }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px;margin-left: 10px;">{{ item.des }}</span>
              </el-option>
            </el-select>
            <!-- <el-alert
              v-if="basicInfo.durationType == 'audio'"
              style="margin-top: 5px;line-height: 15px"
              show-icon
              center
              effect="dark"
              :title="$t('basicInfo.previewNotSupported')"
              type="warning"
              :closable="false"
            >
            </el-alert> -->
          </el-col>
          <el-col
            :span="12"
            v-if="basicInfo.durationType === 'regular'"
          >
            <el-form-item
              prop="regularDuration"
              style="margin-left: 10px;"
            >
              <el-input
                v-model.number="basicInfo.regularDuration"
                type="number"
                :min="0"
                :max="9999"
                style="width: 150px;"
                @input="handleItemUpdated"
              >
                <template slot="append">s</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>

      </el-form-item>
    </el-form>
    <material-drawer
      ref="materialDrawer"
      :visible.sync="drawer"
      @selectionConfirmed="selectionConfirmed"
    />
    <el-drawer
      size="50%"
      class="publicDrawer"
      :title="$t('basicInfo.pruningTitle')"
      :visible.sync="drawerPruning"
      :with-header="true"
      :wrapperClosable="false"
      :show-close="false"
    >
      <div slot="title">
        <div class="headerFlex">
          <div class="headerTitle">{{ $t('basicInfo.pruningTitle') }}</div>
          <!-- <i class="el-icon-close" @click="drawerPruning = false"></i> -->
        </div>
      </div>
      <!-- :before-close="closePruning" -->
      <pruning
        ref="pruning"
        @confirm="confirmPruning"
        @cancel="cancelPruning"
      ></pruning>
    </el-drawer>
  </div>
</template>
<script>
import MaterialDrawer from "@/components/MaterialDrawer/index.vue";
import Pruning from "./Pruning.vue";
import uploadFile from "../uploadFile/index.vue";

export default {
  components: { MaterialDrawer, Pruning, uploadFile },
  props: {
    value: {
      type: Object,
      default: () => ({}), // 默认值为空对象
    },
  },
  watch: {
    value: {
      handler(val) {
        // console.log("watch22");
        this.basicInfo = {
          sceneName: this.$t("basicInfo.scene1"),
          isMute: true,
          durationType: "",
          regularDuration: 0,
          videoTrackClipsBos: [],
          ...this.value,
        };
        // console.log('watch22' + JSON.stringify(val))
        if (this.basicInfo.videoTrackClipsBos.length > 0) {
          this.basicInfo.videoTrackClipsBos =
            this.basicInfo.videoTrackClipsBos.map((clip) => {
              let parsedInOutList = [];
              if (typeof clip.inOutList === "string") {
                try {
                  parsedInOutList = JSON.parse(clip.inOutList); // 将字符串解析为数组或对象
                } catch (e) {
                  console.error("JSON解析失败: ", e);
                }
              }
              return {
                ...clip,
                hover: false,
                type:
                  clip.tracksType ||
                  clip.fileType ||
                  (clip.type && clip.type.toLowerCase()),
                mediaUrl: clip.mediaUrl || clip.url,
                inOutList: Array.isArray(parsedInOutList)
                  ? parsedInOutList
                  : [],
              };
            });
          //当选择的素材为图片、存在文本内容 并且durationType没值时，自动赋值
          if (
            this.basicInfo.videoTrackClipsBos[0].type === "image" &&
            this.basicInfo.audioTrackClipsBos &&
            this.basicInfo.audioTrackClipsBos.content
          ) {
            // this.basicInfo.durationType = "audio";
            // this.$set(this.basicInfo, "durationType", "audio");
            // this.basicInfo = { ...this.basicInfo, durationType: "audio" };
            this.basicInfo = Object.assign({}, this.basicInfo, {
              durationType: "audio",
            });
          }
        }
        if (this.basicInfo.durationType === "video") {
          // this.basicInfo.durationType = "";
          // this.$set(this.basicInfo, "durationType", "");
          // this.basicInfo = { ...this.basicInfo, durationType: "" };
          this.basicInfo = Object.assign({}, this.basicInfo, {
            durationType: "",
          });
        }
        this.changeBackground();
        this.changeDuration();
        if (
          !(
            this.basicInfo &&
            this.basicInfo.audioTrackClipsBos &&
            this.basicInfo.audioTrackClipsBos.content
          )
        ) {
          if (this.basicInfo.durationType === "audio") {
            // this.basicInfo.durationType = "";
            this.basicInfo = { ...this.basicInfo, durationType: "" };
            this.basicInfo = Object.assign({}, this.basicInfo, {
              durationType: "",
            });
          }
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      basicInfo: {
        sceneName: this.$t("basicInfo.scene1"),
        isMute: true,
        durationType: "",
        regularDuration: 0,
        videoTrackClipsBos: [],
        ...this.value,
      },
      drawer: false, //素材库
      drawerPruning: false, //粗剪
      pruningIndex: -1,
      rules: {
        sceneName: [
          {
            required: true,
            message: this.$t("basicInfo.tipSceneName"),
            trigger: ["blur", "change"],
          },
        ],
        videoTrackClipsBos: [
          {
            required: true,
            message: this.$t("basicInfo.tipImport"),
            trigger: ["blur", "change"],
          },
        ],
        regularDuration: [
          {
            validator: (rule, value, callback) => {
              if (
                this.basicInfo.durationType === "regular" &&
                (!value || value <= 0)
              ) {
                callback(new Error(this.$t("basicInfo.tipDuration")));
              } else {
                callback();
              }
            },
            trigger: ["blur", "change"],
          },
        ],
      },
      timeTypes: [
        {
          value: "video",
          label: this.$t("basicInfo.timeVideo"),
          des: this.$t("basicInfo.timeVideoExp"),
        },
        {
          value: "audio",
          label: this.$t("basicInfo.timeAudio"),
          des: this.$t("basicInfo.timeAudioExp"),
          disabled: !(
            this.basicInfo &&
            this.basicInfo.audioTrackClipsBos &&
            this.basicInfo.audioTrackClipsBos.content
          ),
        },
        {
          value: "regular",
          label: this.$t("basicInfo.timeRegular"),
          des: this.$t("basicInfo.timeRegularExp"),
        },
      ],
    };
  },
  computed: {
    computedTimeTypes() {
      return [
        // {
        //   value: "video",
        //   label: this.$t("basicInfo.timeVideo"),
        //   des: this.$t("basicInfo.timeVideoExp"),
        // },
        {
          value: "audio",
          label: this.$t("basicInfo.timeAudio"),
          des: this.$t("basicInfo.timeAudioExp"),
          disabled: !(
            this.basicInfo &&
            this.basicInfo.audioTrackClipsBos &&
            this.basicInfo.audioTrackClipsBos.content
          ),
        },
        {
          value: "regular",
          label: this.$t("basicInfo.timeRegular"),
          des: this.$t("basicInfo.timeRegularExp"),
        },
      ];
    },
  },
  created() {},
  methods: {
    focusInput() {
      // 通过 ref 获取输入框实例并聚焦
      this.$refs.sceneNameInput.focus();
    },
    changeMute() {
      this.basicInfo.isMute = !this.basicInfo.isMute;
      this.handleItemUpdated();
    },
    timeTypeChange() {
      this.basicInfo.regularDuration = 0;
      this.handleItemUpdated();
    },
    //修改背景图片
    changeBackground() {
      let url = "";
      if (this.basicInfo.videoTrackClipsBos.length > 0) {
        url = this.basicInfo.videoTrackClipsBos[0].firstFrameImgUrl;
      } else {
        url = "";
      }
      // console.log(JSON.stringify(this.basicInfo.videoTrackClipsBos));
      this.$emit("update-background", url);
    },
    changeDuration() {
      let duration = 0;
      if (this.basicInfo.videoTrackClipsBos.length > 0) {
        duration = this.basicInfo.videoTrackClipsBos.reduce((minOut, clip) => {
          return Math.min(minOut, clip.out);
        }, this.basicInfo.videoTrackClipsBos[0].out);
      }
      // console.log("duration", duration);
      this.$emit("update-videoDuration", duration);
    },
    //更新数据到上个页面
    handleItemUpdated() {
      this.$emit("item-updated", this.basicInfo);
    },
    init(data) {
      this.basicInfo = { ...data };
    },
    chooseMaterial() {
      // this.$router.push({ path: '/material',query: { isSelectionMode: true }  });
      this.drawer = true;
      this.$nextTick().then((rs) => {
        this.$refs.materialDrawer.$refs.materialLibrary.init(8);
      });
    },
    showLocaMedia(items) {
      this.selectionConfirmed(items);
    },
    selectionConfirmed(items) {
      console.log("选中的素材:", items);
      items.forEach((item) => {
        // if (!item.fileType) {
        //   item.fileType = this.getTypeByUrl(item.url);
        // }
        delete item.id;
        // delete item.type;
        item.type = this.getTypeByUrl(item.url);
        this.basicInfo.videoTrackClipsBos.push({
          ...item,
          out: item.videoLength,
          mediaUrl: item.url,
          hover: false, // 添加一个hover属性来控制蒙层的显示
          inOutList: [{ in: 0, out: item.videoLength, checked: true }],
        });
      });
      if (this.basicInfo.videoTrackClipsBos) {
        if (this.basicInfo.videoTrackClipsBos[0].type == "image") {
          if (
            this.basicInfo.audioTrackClipsBos &&
            this.basicInfo.audioTrackClipsBos.content &&
            !this.basicInfo.durationType
          ) {
            this.basicInfo.durationType = "audio";
          } else {
            this.basicInfo.durationType = "";
          }
          this.basicInfo.regularDuration = 0;
        } else {
          this.basicInfo.durationType = "regular";
          if (this.basicInfo.videoTrackClipsBos[0].out) {
            this.basicInfo.regularDuration =
              this.basicInfo.videoTrackClipsBos[0].out;
          } else {
            this.basicInfo.regularDuration = 0;
          }
        }
      }
      this.changeBackground();
      this.changeDuration();
      // console.log("videoTrackClipsBos:", this.basicInfo.videoTrackClipsBos);
      this.handleItemUpdated();
      // 这里可以执行进一步的处理，例如保存到数据中等
      this.drawer = false;
    },
    removeMaterial(index) {
      this.basicInfo.videoTrackClipsBos.splice(index, 1);
      this.changeBackground();
      this.changeDuration();
      this.handleItemUpdated();
    },
    pruning(item, index) {
      this.drawerPruning = true;
      this.pruningIndex = index;
      this.$nextTick().then((rs) => {
        this.$refs.pruning.init(item);
      });
    },
    cancelPruning(res) {
      this.drawerPruning = false;
      if (this.pruningIndex > -1) {
        this.basicInfo.videoTrackClipsBos[this.pruningIndex].inOutList = res;
        this.handleItemUpdated();
        console.log("取消粗剪", res);
      }
    },
    confirmPruning(res, selectedVideoLength) {
      this.drawerPruning = false;
      if (this.pruningIndex > -1) {
        this.basicInfo.videoTrackClipsBos[this.pruningIndex].inOutList = res;
        this.basicInfo.regularDuration = selectedVideoLength;
        this.handleItemUpdated();
        console.log("确认粗剪", res);
      }
    },
    validate() {
      return new Promise((resolve, reject) => {
        this.$refs["basicInfoForm"].validate((valid) => {
          if (valid) {
            resolve();
          } else {
            reject(new Error("Validation failed"));
          }
        });
      });
    },
    getTempInfo() {
      return this.basicInfo;
    },
  },
};
</script>

<style lang="scss" scoped>
.basic-info-title {
  width: 336px;
  height: 36px;
  // background: rgba(1, 86, 255, 0.05);
  position: relative;
  .title-bg-svg {
    width: 100%;
    height: 100%;
    opacity: 0.05;
    position: absolute;
  }
}
.scene-video-header {
  min-height: 64px;
  background: #f3f3f3;
  border-radius: 11px 11px 0px 0px;
  padding: 12px 16px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.9);
  line-height: 22px;
  text-align: left;
  font-style: normal;
  .icon-edit {
    font-size: 14px;
  }
  ::v-deep .el-input__inner {
    border: none;
    background: transparent;
  }
  .scene-isMute {
    height: 24px;
    background: #e4e4e7;
    border-radius: 16px;
    padding: 0 8px 0 4px;

    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.26);
    line-height: 24px;
    text-align: left;
    font-style: normal;
    cursor: pointer;

    .cricle-white {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: #ffffff;
      box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
      margin-right: 5px;
      i {
        font-size: 12px;
        color: #999999;
        &.activated {
          color: #0092ff;
        }
      }
    }
    &.activated {
      background: #0092ff;
      color: #ffffff;
    }
  }
  .scene-video-num {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.26);
    line-height: 20px;
    text-align: left;
    font-style: normal;
    margin-top: 2px;
  }
}
.aspect-ratio-box-container {
  height: 300px;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* 调整间距 */
  background: white;
  border-radius: 0 0 12px 12px;
  border: 1px solid #ededed;
  padding: 16px;
}
.upload-size {
  width: 135px;
  height: 240px;
}
.aspect-ratio-box {
  width: 135px;
  height: 240px;
  border: 1px solid #0092ff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin-bottom: 5px; /* 调整间距 */
  position: relative;
}

.material-thumbnail {
  width: 100%;
  height: 100%; /* 根据需要调整高度 */
  object-fit: cover;
  border-radius: 3px;
}

.material-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
}

.video-duration {
  position: absolute;
  right: 5px;
  bottom: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 2px 5px;
  border-radius: 3px;
  font-size: 12px;
}

.el-form-item {
  margin-bottom: 0px;
}
::v-deep .el-form--label-top .el-form-item__label {
  padding: 0;
}
.el-input-group > ::v-deep .el-input__inner {
  border-radius: 0 !important;
}
</style>
