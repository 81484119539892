var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "folder",
      style: { zIndex: _vm.item.hover ? 10 : "auto" },
      on: { mouseover: _vm.mouseoverItem, mouseleave: _vm.mouseleaveItem },
    },
    [
      _c(
        "div",
        { staticClass: "flex-center folder-img", on: { click: _vm.open } },
        [
          _vm.item.type === "system"
            ? _c("img", {
                staticClass: "img-forder",
                attrs: {
                  src: require("../../assets/images/folder-system.png"),
                  alt: "",
                },
              })
            : _c("img", {
                staticClass: "img-forder",
                attrs: {
                  src: require("../../assets/images/folder-user.png"),
                  alt: "",
                },
              }),
        ]
      ),
      _c(
        "div",
        { staticClass: "folder-content" },
        [
          _c(
            "div",
            { staticClass: "flex-row-center" },
            [
              _vm.item.type === "system"
                ? _c(
                    "el-tooltip",
                    { attrs: { content: _vm.$t("material.systemMaterial") } },
                    [
                      _c("div", { staticClass: "folder-system flex-center" }, [
                        _c("i", { staticClass: "iconfont icon-xitongsucai" }),
                      ]),
                    ]
                  )
                : _vm._e(),
              _c("div", { staticClass: "folder-title single-line-ellipsis" }, [
                _vm._v(_vm._s(_vm.item.name)),
              ]),
            ],
            1
          ),
          _vm.getLabels(_vm.item.label)
            ? _c(
                "el-tooltip",
                { attrs: { content: _vm.getLabels(_vm.item.label) } },
                [
                  _c(
                    "div",
                    { staticClass: "folder-label single-line-ellipsis" },
                    [_vm._v(" " + _vm._s(_vm.getLabels(_vm.item.label)) + " ")]
                  ),
                ]
              )
            : _c("div", { staticClass: "folder-label" }),
          _vm.item.hover && _vm.item.type !== "system"
            ? _c(
                "div",
                { staticClass: "folder-operation flex-center f-around" },
                [
                  _vm.item.type !== "system"
                    ? _c("el-tooltip", { attrs: { content: _vm.$t("edit") } }, [
                        _c("i", {
                          staticClass: "iconfont icon-edit color-blue mr10",
                          on: { click: _vm.openEditDialog },
                        }),
                      ])
                    : _vm._e(),
                  _vm.item.type !== "system"
                    ? _c(
                        "el-tooltip",
                        { attrs: { content: _vm.$t("remove") } },
                        [
                          _c("i", {
                            staticClass: "iconfont icon-delete color-blue mr10",
                            on: { click: _vm.deleteMaterialType },
                          }),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.item.hover && _vm.item.type === "system"
            ? _c(
                "div",
                { staticClass: "folder-operation flex-center font12" },
                [_vm._v(" " + _vm._s(_vm.$t("material.systemMaterial")) + " ")]
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }